<template>
  <v-container
    id="user-profile"
    fluid
    tag="section"
  >
    <v-row justify="center">
      <v-col
        cols="12"
        md="8"
      >
        <base-material-card color="#174282">
          <template v-slot:heading>
            <div class="display-2 font-weight-light">
              Nouvelle consultation
            </div>
          </template>

          <v-form
            ref="form"
            v-model="valid"
          >
            <v-container class="py-0">
              <v-row>
                <v-col
                  cols="12"
                  md="11"
                >
                  <v-select
                    label="Droit"
                    class="purple-input"
                    v-model="selectedTheme"
                    :items="themes"
                    item-text="name"
                    item-value="id"
                    :rules="[rules.required, ]"
                  ></v-select>
                  <v-select
                    v-model="selectedBoxe"
                    :items="boxes"
                    item-text="name"
                    item-value="id"
                    label="Box"
                    :rules="[rules.required, ]"
                  ></v-select>
                  <v-select
                    v-model="selectedLawyer"
                    :items="lawyers"
                    item-text="name"
                    item-value="id"
                    label="Avocat"
                    :rules="[rules.required, ]"
                  ></v-select>
                  <v-textarea
                    solo
                    name="description"
                    label="Description"
                    v-model="description"
                  ></v-textarea>
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  cols="12"
                  md="12"
                >
                  <h5 class="headline">Horaires</h5>
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  cols="12"
                  md="4"
                >
                  <v-select
                    label="Date de la consultation"
                    v-model="selectedDate"
                    :items="days"
                    item-text="id"
                    item-value="date"
                    :rules="[rules.required, ]"
                  />
                </v-col>
                <v-col
                  cols="12"
                  md="4"
                >
                  <v-text-field
                    label="Heure de début"
                    type="time"
                    v-model="timeStart"
                    :rules="[rules.required, ]"
                    required
                  />
                </v-col>
                <v-col
                  cols="12"
                  md="4"
                >
                  <v-text-field
                    label="Heure de fin"
                    type="time"
                    v-model="timeEnd"
                    :rules="[rules.rangeTime, ]"
                    required
                  />
                </v-col>
              </v-row>
              <v-col
                cols="12"
                class="text-right"
              >
                <v-btn
                  color="#174282"
                  class="mr-2"
                  :disabled="!valid"
                  @click="[createConsultation(), reset()]"
                >Enregistrer et continuer</v-btn>
                <v-btn
                  color="#174282"
                  class="mr-2"
                  :disabled="!valid"
                  @click="[createConsultationAndRedirect()]"
                >Enregistrer et quitter</v-btn>
              </v-col>
              <v-col
                cols="12"
                class="text-center"
              ></v-col>
            </v-container>
          </v-form>
        </base-material-card>
<base-material-card color="#174282">
    <template v-slot:heading>
      <div class="display-2 font-weight-light">
        Nouveau avocat
      </div>
     </template>
          <v-form
            ref="add"
            v-model="addValid"
          >
          <v-row>
            <v-col cols=6>
            <v-text-field
            label="Nom de l'avocat"
            v-model="newAvocat"
            :rules="[rules.required, ]"
            required
            />
            </v-col>
            <v-col cols=6>
                <v-btn
                  color="#174282"
                  class="mr-2"
                  :disabled="!addValid"
                  @click="[createAvocat(), resetAvocat()]"
                >Ajouter cet avocat</v-btn>
            </v-col>
          </v-row>
        </v-form>
</base-material-card>
      </v-col>
    </v-row>
    <v-snackbar
      v-model="snackbar"
      :timeout="timeout"
    >
      {{ text }}

      <template v-slot:action="{ attrs }">
        <v-btn
          color="blue"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          X
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
  import { httpPost, httpGet } from '@/plugins/axios'
  export default {
    name: 'SignIn',
    data () {
      return {
        valid: true,
        addValid: true,
        newAvocat: '',
        snackbar: false,
        text: 'Consultation bien enregistrée',
        timeout: 2000,
        days: [
          { id: 'jeudi 30 septembre', date: '2021-09-30' },
          { id: 'vendredi 1 octobre', date: '2021-10-01' },
        ],
        selectedDate: null,
        timeStart: '',
        timeEnd: '',
        name: '',
        themes: [],
        selectedTheme: null,
        lawyers: [],
        selectedLawyer: null,
        selectedBoxe: null,
        boxes: [],
        place: '',
        description: '',
        rules: {
          required: value => !!value || 'Requis.',
          rangeTime: value => value >= this.timeStart || 'La date de fin doit être après la date de début',
        },
      }
    },
    mounted () {
      this.getBoxes()
      this.getLawyers()
      this.getThemes()
    },
    methods: {
      addSchedule () {
        this.horaires.push({ dateStart: null, dateEnd: null, maxUsers: null })
      },
      async createAvocat() {
        const response = await httpPost('createAvocat', {
            name: this.newAvocat,
            }
        )
        if(response){
          this.getLawyers()
        }
      },
      async getThemes () {
        const response = await httpGet('getThemes')
        this.themes = []
        response.Themes.forEach(theme => {
          this.themes.push({ id: theme.FFD_ID, name: theme.FFD_Name })
        })
      },
      reset () {
        this.$refs.form.reset()
      },
      resetAvocat () {
        this.$refs.add.reset()
      },
      redirect () {
        window.location.href = ''
      },
      async getBoxes () {
        const response = await httpGet('getBoxes')
        this.boxes = []
        response.boxes.forEach(boxe => {
          this.boxes.push({ id: boxe.FBX_ID, name: boxe.FBX_Name })
        })
      },
      async getLawyers () {
        const response = await httpGet('getLawyers')
        this.lawyers = []
        response.lawyers.forEach(lawyer => {
          this.lawyers.push({ id: lawyer.AVO_ID, name: lawyer.AVO_Name })
        })
      },
      async createConsultation () {
        const response = await httpPost('createConsultation', {
          name: this.name,
          theme: this.selectedTheme,
          boxe: this.selectedBoxe,
          lawyer: this.selectedLawyer,
          description: this.description,
          day: this.selectedDate,
          start: this.timeStart,
          end: this.timeEnd,
        })
        if (response) {
          this.snackbar = true
        }
      },
      async createConsultationAndRedirect () {
        const response = await httpPost('createConsultation', {
          name: this.name,
          theme: this.selectedTheme,
          boxe: this.selectedBoxe,
          lawyer: this.selectedLawyer,
          description: this.description,
          day: this.selectedDate,
          start: this.timeStart,
          end: this.timeEnd,
        })
        if (response) {
          this.redirect()
        }
      },
    },
  }
</script>
